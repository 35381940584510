<template lang="pug">
include ../pug/svg
section#org
	Form.wrapper(ref="organisationForm" :validation-schema="organisationSchema" v-slot="{ values, errors, meta }")
		FieldText(name="name" v-model="name" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" :label="terminology('organisation', 'organisation', 'singular') + ' Name'")
		
		FieldText(name="subdomain" v-model="subdomain" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" :disabled="true" label="Subdomain")
		
		FieldText(name="websiteUrl" v-model="websiteUrl" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" :disabled="true" label="Website URL")
		
		FieldText(name="emailGeneral" v-model="emailGeneral" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" :required="true" label="Email (General)")
		
		FieldText(name="emailSales" v-model="emailSales" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" label="Email (Sales)")
		
		FieldText(name="emailSupport" v-model="emailSupport" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" label="Email (Support)")
		
		FieldText(name="phoneGeneral" v-model="phoneGeneral" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" label="Telephone (General)")
		
		FieldText(name="phoneSales" v-model="phoneSales" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" label="Telephone (Sales)")
		
		FieldText(name="phoneSupport" v-model="phoneSupport" :cancelled="cancelled" :errors="errors" @keyup="enableCheck" label="Telephone (Support)")
	span.req Required
</template>

<script>
import { Form } from 'vee-validate';
import * as Yup from 'yup';
import { mapFields } from 'vuex-map-fields';	
import FieldText from '../components/FieldText';

export default {
	name: 'ManageAccountOrganisation',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	components: {
		Form,
		FieldText,
	},
	data() {
		const req = 'Required';
		const inv = 'Invalid';
		const organisationSchema = Yup.object().shape({
			name: Yup.string().required(req).nullable(),
			subdomain: Yup.string().required(req).nullable(),
//			websiteUrl: Yup.string().email(inv).required(req).nullable(),
			emailGeneral: Yup.string().email(inv).required(req).nullable(),
			emailSales: Yup.string().email(inv).nullable(),
			emailSupport: Yup.string().email(inv).nullable(),
			phoneGeneral: Yup.string().nullable(),
			phoneSales: Yup.string().nullable(),
			phoneSupport: Yup.string().nullable(),
		});
		
		return {
			organisationSchema,
			invalidFields: [],
		}
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' ' + this.terminology('organisation', 'organisation', 'singular'),
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},
		});		
	},
	computed: {
		...mapFields('gui', [ // get/set vuex store
			'organisation',
			'organisation.name',
			'organisation.subdomain',
			'organisation.websiteUrl',
			'organisation.emailGeneral',
			'organisation.emailSales',
			'organisation.emailSupport',
			'organisation.phoneGeneral',
			'organisation.phoneSales',
			'organisation.phoneSupport',
		]),
	},
	watch: {
		cancelled: {
			// parent triggered cancel
			handler(val) {
				if (!val) { // store reset complete
					const form = this.$refs.organisationForm;
					const errors = form.getErrors();
					
					for(const field in form.getValues()) {
						form.setFieldTouched(field, false);
						
						if (field in errors) {
							form.setFieldError(field, '-');					
						}
					}
				}
			},
			deep: true,
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		enableCheck() {
			const meta = this.$refs.organisationForm.getMeta();
			this.$emit('storeUpdated', {
				screen: 'organisation',
				valid: meta.valid,
			});
		},
	},
}
</script>

<style lang="scss">
#org {
	form {
		display: flex;
		flex-wrap: wrap;
		margin-top: 30px;
	}
	input[disabled] {
		cursor: not-allowed;
	}
	@media screen and (max-width: 614px) {
		form {
			flex-direction: column;
		}
		.field {
			width: 100%;
			max-width: none;
		}
	}
	@media screen and (min-width: 615px) and (max-width: 1109px) {
		.field {
			width: calc(50% - 7.5px);
			&:nth-child(odd) {
				margin-right: 15px;
			}
		}
	}
	@media screen and (min-width: 1110px) {
		.field {
			width: calc(33.333% - 10px);
			margin-right: 15px;
			&:nth-child(3n+3) {
				margin-right: 0;
			}
		}
	}
}
</style>
